<template>
  <div class="component-inner">

    <div class="team-items team-links mx-auto">

      <article
      v-for="(post) in posts"
      :key="post.id"
      class="team-item">

        <figure class="image-column">

          <img
          v-if="post.media"
          class="object-cover aspect-video "
          :src="`${baseURL}/${post.media.media_details.sizes['large'].source_url}`"
          :height="post.media.media_details.sizes['large'].height"
          :width="post.media.media_details.sizes['large'].width"
          :alt="post.media.alt" />

        </figure>

        <div class="text-column content">
          <h5 class="name">{{ post.title }}</h5>

          <div
          v-if="post.contact"
          class="contact-info">

            <dl class="email definition-list" v-if="post.contact.email">
              <dt class="label">E-post:</dt>
              <dd class="value" v-html="post.contact.email"></dd>
            </dl>

            <dl class="phone definition-list" v-if="post.contact.phone_numbers">
              <div class="list-item" v-for="entry in post.contact.phone_numbers">
                <dt class="label">{{ entry.label }}:</dt>
                <dd class="value">{{ entry.number }}</dd>
              </div>
            </dl>

            <dl class="address definition-list" v-if="post.contact.address && post.contact.address.street">
              <dt class="label">Besøksadresse:</dt>
              <dd class="value">{{ post.contact.address.street }} {{ post.contact.address.zip }} {{ post.contact.address.city }}</dd>
            </dl>

            <dl class="county definition-list" v-if="post.contact.county">
              <dt class="label">Kommuner:</dt>
              <dd class="value">{{ post.contact.county }}</dd>
            </dl>

          </div>

        </div>

        <!-- <header class="flex-none flex flex-col lg:flex-row w-full justify-between gap-8">
          <h5 class="mb-0">{{ post.title }}</h5>
          <h3 class="mb-0">
            <router-link
            class="flex items-center lg:text-xl text-brand-green font-SuisseIntlBook"
            :to="post.url">
            <span>Mer informasjon om {{ post.title }}</span>
            <svg class="flex-none h-4 w-auto fill-current px-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"></path></svg>
            </router-link>
          </h3>

        </header> -->

      </article>

    </div>

  </div>


</template>

<script>
export default {
  name: 'Team',
  props: {
    block: Object
  },
  data() {
    return {
      posts: [],
      baseURL: process.env.MIX_API_BASE_URL,
    }
  },
  methods: {
    async getPosts() {
      try {
        const response = await axios.get(
          this.baseURL + '/api/wp/v2/kontakt', {
            params: {}
          }
        )
        const results = response.data
        if ( !results || results.length == 0 ) {
          // this.errors.push('Error, 404')
          // //console.log(this.errors)
        }
        this.posts = results.map(post => ({
          id: post.id,
          type: post.type,
          slug: post.slug,
          url: post.vueData.url,
          title: post.title.rendered,
          excerpt: post.acf.excerpt.excerpt,
          media: post.better_featured_image,
          contact: post.acf.contactGroup ? post.acf.contactGroup.contact : false
        }))

      }
      catch (e) {
        this.errors.push(e)
      }
      return this.doAction()

    },

    doAction() {},
  },

  mounted() {
    this.getPosts()
  },
}
</script>
