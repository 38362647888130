jQuery(document).ready(function($){


    var Ajax =
    {
        token: "",

        // url: "https://nfp.stage.webcore.no/app/themes/nfp/resources/includes/ajax.php",
        url: `${process.env.MIX_API_BASE_URL}/app/themes/nfp/resources/includes/ajax.php`,

        execute: function (payload, success, failure)
        {
            var contentType = "application/x-www-form-urlencoded; charset=UTF-8";
            var processData = true;
            var cache = true;
            var async = payload.async === false ? false : true;

            if (payload.data instanceof FormData)
            {
                // set config values
                processData = false;
                cache = false;
                contentType = false;

                payload.data.append ("class", payload.class);
                payload.data.append ("method", payload.method);
                // Can only store string-data so JSON-ify params
                payload.data.append ("json", JSON.stringify (payload.params));

                // set data proper
                payload = payload.data;
            }
            else
                payload.token = Ajax.token;

            $.ajax ({
                type: "POST",
                url: Ajax.url,
                contentType: contentType,
                processData: processData,
                cache: cache,
                data: payload,
                async: async,
                success: function (data) {
                    // console.log ('execute data', data);
                    if (data.result === "success")
                    {
                        if (success instanceof Function)
                            success (data);
                    }
                    else
                    {
                        if (failure instanceof Function)
                            failure (data);
                    }
                }
            });
        }
    };

    window.Ajax = Ajax;




    Ajax.saveBookmarks = function (id, bookmarks, success)
    {
        this.execute ({ class: "User", method: "setMeta", params: { user_id: id, bookmarks: bookmarks }},
            function (data) {
                if (success instanceof Function)
                    success (data);
            }
        );
    };

    Ajax.fetchBookmarks = function (id, success)
    {

        this.execute ({ class: "User", method: "getMeta", params: { user_id: id }},
            function (data) {
                if (success instanceof Function)
                    success (data);
            },
            function(data) { console.log('ajax failed', data) }
        );
    };

    Ajax.login = function (id, success)
    {
      this.execute ({ class: "User", method: "login", params: { user_id: id }},
        function (data) {
          if (success instanceof Function)
          success (data);
        },
        function(data) { console.log('ajax failed', data) }
      );
    };

    Ajax.logout = function (id, success)
    {
      // this.execute ({ class: "User", method: "logout" },
      //     function (data) {
      //        if (success instanceof Function)
      //           success (data);
      //     },
      //     function (data) {
      //           // showModal (data.message);
      //     }
      // );
      this.execute ({ class: "User", method: "logout", params: { user_id: id }},
        function (data) {
          console.log("ajax logout");
          if (success instanceof Function)
          success (data);
        },
        function(data) { console.error('logout failed', data) }
      );
    };

    Ajax.isLoggedIn = function (id, success)
    {
      this.execute ({ class: "User", method: "isLoggedIn", params: { user_id: id }},
        function (data) {
          if (success instanceof Function)
          success (data);
        },
        function(data) { console.log('ajax failed', data) }
      );
    };

});

export default {
  namespaced: true,

  actions: {
    fetchBookmarks ({ state }, payload) {
      // console.log('fetching bookmarks', payload);
      Ajax.fetchBookmarks(payload.id, payload.success);
    },

    saveBookmarks({ state }, payload) {
      Ajax.saveBookmarks(payload.id, payload.bookmarks, payload.success)
    },

    login({ state }, payload) {
      return new Promise((resolve, reject) => {

        Ajax.login(payload.id, function(data) {
          // console.log("AJAX LOGIN CALLBACK")
          return resolve(data)
        })
      })
    },

    logout({ state }, payload) {
      console.log("ajax logout", payload);
      Ajax.logout(payload.success);
      // return new Promise((resolve, reject) => {
      //   Ajax.logout(payload.id, function(data) {
      //     return resolve(data)
      //   });
      // })
    },

    // logoutUser({ state }, payload) {
    //   Ajax.logoutUser((data) => {
    //     console.log("logged out due to inactivity");
    //   })
    // },

    isLoggedIn({ state }, payload) {
      // console.log("AJAX ISLOGGEDIN FIRED", payload);
      // return new Promise((resolve, reject) => {
      //   Ajax.isLoggedIn(payload.id, function(data) {
      //     return resolve(data)
      //   });
      // })
      Ajax.isLoggedIn(payload.id, payload.success);
    },
  }
}
